export default {
  data () {
    return {
      files: [],
      filesToSubmit: [],
      fixedMaxFiles: 50,
    };
  },
  computed: {
    maxFiles () {
      return this.fixedMaxFiles;
    },
  },
  methods: {
    resetFiles () {
      this.files = [];
      this.filesToSubmit = [];
    },
    startUpload (file) {
      return this.$api.uploadFile(file);
    },
    removeFile (file) {
      if (file.remove) {
        file.remove();
      }

      this.removeFileFromIndex(file);
    },
    replaceFile (file) {
      this.removeFile(file);
      this.$refs.uploader.openFilePicker(file);
    },
    removeFileFromIndex (file) {
      this.files = this.files.filter(i => i !== file);
    },
    uploadsFinishedAndReadyToSubmit () {
      const limit = Math.max(0, Math.min(this.maxFiles, this.files.length));

      this.filesToSubmit = [];
      for (let i = 0; i < limit; i++) {
        const file = this.files[i];

        if (file.uploading) {
          return false;
        }

        const fileToSubmit = {
          tmp: !file.url,
        };

        if (fileToSubmit.tmp) {
          fileToSubmit.code = file.code;
        } else {
          fileToSubmit.id = file.id;
        }

        this.filesToSubmit.push(fileToSubmit);
      }

      return true;
    },
  },
};
